<template>
    <swiper ref="mySwiper" :options="swiperOption">
        <swiper-slide class="emoji-page" :key="n" v-for="n in emojiPage">
            <div
                :key="index"
                v-for="(item, index) in emojisByPage(n - 1)"
                class="emoji-item"
            >
                <span @click.stop="emojiInsert(item)">{{ item }}</span>
            </div>
            <div style="clear: both;"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
</template>
<style lang="less">
.emoji-page {
    padding-bottom: 20px;
    cursor: default;
    .emoji-item {
        width: 14.28%;
        margin: 10px 0;
        line-height: 1;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        span {
            font-size: 22px;
        }
    }
}
</style>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
    name: "emoji-swiper",
    // components: { Swiper, SwiperSlide },
    data() {
        return {
            swiperOption: {
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                }
            }
        };
    },
    computed: {
        emojiPage() {
            return parseInt((this.emojis.length + 20) / 21);
        },
        emojis() {
            return this.$store.state.emojis || [];
        },
        swiper() {
            return this.$refs.mySwiper.swiper;
        }
    },
    methods: {
        emojisByPage(n) {
            return this.emojis.slice(n * 21, (n + 1) * 21);
        },
        emojiInsert(emoji) {
            this.$emit("emoji", emoji);
        }
    }
};
</script>
