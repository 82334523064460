<!--
 * @Author: Raymonda
 * @Date: 2020-06-01 15:20:54
 * @LastEditTime: 2020-07-20 01:22:10
 * @LastEditors: Raymonda
 * @Description: Description
 * @当你不能够再拥有，你唯一可以做的，就是令自己不要忘记！
-->
<template>
    <div class="comment-input">
        <div class="comment-head">
            <img v-if="icon" class="head-icon" :src="icon" />
            <span class="head-title">{{ title | language }}</span>
            <span class="fix-flex"></span>
            <span v-if="desc" class="head-text">{{ desc | language }}</span>
        </div>
        <Input
            :placeholder="'标题' | language"
            v-model="commentTitle"
            class="title-input"
            v-if="showTitle"
        />
        <textarea
            v-model="commentContent"
            class="input-main"
            :placeholder="'说点什么' | language"
            ref="text"
        ></textarea>
        <div class="comment-action">
            <div v-if="showtags" class="comment-tags">
                <div
                    :class="[activeTag == index ? 'active-tag' : '']"
                    class="comment-tag click-op"
                    :key="index"
                    v-for="(tag, index) in tags"
                    @click.stop="activeTag = activeTag == index ? -1 : index"
                >
                    {{ tag.name }}
                </div>
            </div>
            <div class="fix-flex"></div>
            <slot name="tip"></slot>
            <Poptip
                trigger="click"
                class="emoji-icon"
                v-model="showEmoji"
                title=""
            >
                <emoji-swiper
                    @emoji="changeEmoji"
                    slot="content"
                    v-if="showEmoji"
                ></emoji-swiper>
                <div class="action-item click-bg">
                    <img
                        class="item-icon item-icon-1"
                        src="http://cdn.sjtuair.com/e78239c0-a3de-11ea-9bdb-fbe6162f3b5f.svg"
                    />
                    <span class="item-span">{{ "表情" | language }}</span>
                </div>
            </Poptip>

            <Upload
                name="file"
                action="https://upload.qbox.me"
                :data="qnUploadData"
                :multiple="true"
                :max-size="3072"
                :on-exceeded-size="overSize"
                :before-upload="beforeFileUpload"
                :on-success="handleChange"
                :show-upload-list="false"
                accept=".jpg,.jpeg,.png,.pdf"
            >
                <div class="action-item click-bg">
                    <img
                        class="item-icon item-icon-2"
                        src="http://cdn.sjtuair.com/286bcf50-a3df-11ea-9bdb-fbe6162f3b5f.svg"
                    />
                    <span class="item-span">{{ "图片" | language }}</span>
                </div>
            </Upload>
            <div @click="submit" class="action-btn click-op">
                <img
                    class="item-icon"
                    src="http://cdn.sjtuair.com/2596b650-a3df-11ea-9bdb-fbe6162f3b5f.svg"
                />
                <span>{{ "我要发布" | language }}</span>
            </div>
        </div>
        <div v-if="imgs.length" class="comment-imgs">
            <div :key="i" class="comment-img" v-for="(img, i) in imgs">
                <div class="delete-icon">
                    <Icon @click="removeImg(i)" type="ios-trash" />
                </div>
                <img :src="img" />
            </div>
        </div>
    </div>
</template>

<script>
import uuidv1 from "uuid/v1";
import emojiSwiper from "@/compontents/home/emojiSwiper.vue";
import { qiniuUploadMixin } from "@/mixin/qiniuUpload";
import { languageTranslation } from "@/assets/util/language";
export default {
    name: "comment",
    mixins: [qiniuUploadMixin],
    props: {
        icon: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
        desc: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        // 由父组件处理提交事件
        cb: {
            type: Boolean,
            default: false,
        },
        showtags: {
            type: Boolean,
            default: false,
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        emojiSwiper,
    },
    data() {
        return {
            commentContent: "",
            commentTitle: "",
            swiperOption: {
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            },
            imgs: [],
            activeTag: -1,
            showEmoji: false,
        };
    },
    created() {
        this.fetchTags();
    },
    computed: {
        tags() {
            return [];
            // return this.$store.state.study.tagList;
        },
    },
    methods: {
        fetchTags() {
            // this.$store.dispatch("study/getTagList");
        },
        beforeFileUpload(file) {
            let ext = file.name.split(".").reverse()[0];
            if (!ext) {
                this.$Message.error({
                    background: true,
                    content: languageTranslation("不支持无后缀名文件上传"),
                });
            }

            this.qnUploadData.key = `${uuidv1()}.${ext}`;
        },
        overSize() {
            this.$Message.error("上传图片大小不能超过3MB!");
        },
        handleChange(resp, file, fileList) {
            if (file.status == "finished") {
                let url = this.joinCDN(resp.key);
                this.imgs.push(url);
            }
        },
        changeEmoji(emoji) {
            this.commentContent = this.commentContent + emoji;
        },
        removeImg(i) {
            this.imgs.splice(i, 1);
        },
        async submit() {
            if (this.showTitle && !this.commentTitle) {
                return this.$Message.error("请输入标题");
            }
            if (!this.commentContent) {
                return this.$Message.error("请输入内容");
            }
            if (!this.cb && !this.type) {
                return this.$Message.error("未知发帖类型");
            }
            let data = {
                content: this.commentContent,
                title: this.commentTitle,
            };
            if (this.type) {
                data.type = this.type;
            }
            if (this.imgs?.length) {
                data.imgs = this.imgs;
            }
            if (this.activeTag > -1) {
                data.tag = this.tags[this.activeTag]._id;
            }
            // 报告给父级
            if (this.cb) {
                return this.$emit("submit", data);
            }
            await this.$http.post("/post/add", data);
            this.$Message.success("已发布！");
            this.reset();
            this.$store.dispatch("getPostList", {
                pageNum: 0,
                cond: { type: this.type },
            });
        },
        reset() {
            this.commentContent = "";
            this.commentTitle = "";
            this.imgs = [];
            this.activeTag = -1;
        },
    },
};
</script>

<style lang="less" scoped>
@active_color: #121958;
.comment-input {
    background-color: #fff;
    box-shadow: 0px 1px 10px 0px rgba(179, 179, 179, 0.5);
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    .comment-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .head-icon {
            width: 25px;
            height: 25px;
            margin-right: 20px;
        }
        .head-title {
            font-size: 18px;
            color: @active_color;
        }
        .head-text {
            font-size: 14px;
            color: #9b9b9b;
        }
    }
    .title-input {
        margin-top: 15px;
        font-size: 14px;
        letter-spacing: 1px;
    }
    /deep/::-webkit-input-placeholder {
        font-size: 14px;
        color: #c5c8ce;
    }
    .input-main {
        border: 1px solid #dcdee2;
        width: 100%;
        height: 100px;
        resize: none;
        outline: none;
        border-radius: 4px;
        padding: 10px;
        font-size: 14px;
        color: #9b9b9b;
        font-family: Arial, Helvetica, sans-serif;
        margin-top: 15px;
        letter-spacing: 1px;
        &:focus {
            border-color: #57a3f3;
            outline: 0;
            box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
        }
    }
    .comment-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 14px;
        flex-wrap: wrap;
        .comment-tags {
            display: flex;
            align-items: center;
            .comment-tag {
                background-color: #e8e6e4;
                cursor: pointer;
                border-radius: 20px;
                color: @active_color;
                font-size: 12px;
                padding: 3px 12px;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
            .active-tag {
                background-color: #cd1f46;
                color: #fff;
            }
        }
        /deep/.ivu-poptip-popper {
            width: 300px;
            .swiper-pagination {
                bottom: 0px;
            }
        }
        .action-item {
            padding: 0 20px;
            height: 38px;
            background: rgba(255, 255, 255, 1);
            border-radius: 19px;
            border: 1px solid rgba(232, 230, 244, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
            cursor: pointer;
            .item-icon {
                margin-right: 10px;
            }
            .item-icon-1 {
                width: 22px;
                height: 22px;
            }
            .item-icon-2 {
                width: 22px;
                height: 22px;
            }
            .item-span {
                color: #000;
                font-weight: 600;
            }
        }
        .action-btn {
            cursor: pointer;
            padding: 0 10px;
            height: 38px;
            background: rgba(67, 151, 255, 1);
            box-shadow: 0px 1px 9px 0px rgba(228, 230, 233, 1);
            border-radius: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: bold;
            .item-icon {
                width: 22px;
                height: 22px;
                margin-right: 10px;
            }
        }
    }
    .comment-imgs {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px;
        .comment-img {
            margin-right: 10px;
            margin-bottom: 10px;
            width: 100px;
            height: 100px;
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
            .delete-icon {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.33);
                color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                font-size: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0.6;
                transition: all 0.25s;
                .ivu-icon {
                    cursor: pointer;
                }
                &:hover {
                    background-color: rgba(0, 0, 0, 0.66);
                    opacity: 1;
                }
            }
        }
    }
}
@media screen and (max-width: 960px) {
    .emoji-icon {
        display: none;
    }
}
</style>
